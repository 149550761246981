import {
  Card,
  HeadingText,
  ParagraphText,
  StackLayout,
  TextAction,
} from '@leagueplatform/genesis-core';

export interface TwoActionsCardWidgetProps {
  title: string;
  description: string;
  image: string;
  imageAltText: string;
  actions: { title: string; link: string }[];
}

export const TwoActionsCardWidget = ({
  title,
  description,
  image,
  imageAltText,
  actions,
}: TwoActionsCardWidgetProps) => (
  <Card.Elevated
    css={{
      padding: '0',
      boxShadow: '0px 2px 6px 0px #0000001F !important',
      borderRadius: '8px',
      maxWidth: '100%',
      border: 'none',
      backgroundColor: 'inherit',
      alignItems: 'center',
    }}
    imagePosition="end"
  >
    <Card.Image
      image={image}
      imageAlt={imageAltText}
      css={{
        width: '98px',
        height: '98px',
        objectFit: 'cover',
        marginRight: '24px',
      }}
    />
    <Card.Section>
      <HeadingText level="2" size="sm">
        {title}
      </HeadingText>
      <ParagraphText
        css={{
          width: '180px',
          '@mobile': { width: '100%' },
        }}
      >
        {description}
      </ParagraphText>
      <StackLayout
        css={{
          marginTop: '$oneAndHalf',
        }}
      >
        {actions.map((action) => (
          <TextAction
            key={action.title}
            href={action.link}
            css={{
              display: 'block',
              textDecoration: 'none',
              marginBottom: '0.5rem',
              cursor: 'pointer',
              color: 'inherit',
            }}
          >
            {action.title}
          </TextAction>
        ))}
      </StackLayout>
    </Card.Section>
  </Card.Elevated>
);
