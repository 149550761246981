import React from 'react';
import {
  Card,
  HeadingText,
  ParagraphText,
  StackLayout,
  Image,
  Box,
} from '@leagueplatform/genesis-core';

interface CustomerServiceCardProps {
  title: string;
  description: string;
  info: string;
  body: string;
  icon: string;
}

export const CustomerServiceCard: React.FC<CustomerServiceCardProps> = ({
  title,
  description,
  info,
  body,
  icon,
}) => (
  <>
    <HeadingText
      level="2"
      size="xl"
      css={{
        fontSize: '24px',
        fontWeight: 700,
        lineHeight: '32px',
        letterSpacing: '0.25px',
        textAlign: 'left',
        marginBottom: '$one',
        marginTop: '$three',
      }}
    >
      Get Help Now
    </HeadingText>
    <Card.Elevated
      css={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '24px',
        boxShadow: '0px 2px 6px 0px #0000001F',
        borderRadius: '8px',
        border: 'none',
        backgroundColor: 'inherit',
      }}
    >
      <Image
        src={icon}
        alt="Customer Service"
        css={{
          width: '42px',
          height: '42px',
          marginRight: '12px',
        }}
      />
      <StackLayout
        css={{
          flex: 1,
        }}
      >
        <HeadingText
          level="3"
          size="lg"
          css={{ typography: '$subtitleOne', marginBottom: '4px' }}
        >
          {title}
        </HeadingText>
        <Box
          css={{ typography: '$bodyTwo' }}
          dangerouslySetInnerHTML={{ __html: info }}
        />
        <ParagraphText css={{ typography: '$bodyTwo' }}>
          {description}
        </ParagraphText>
        <ParagraphText css={{ whiteSpace: 'pre-line', typography: '$bodyTwo' }}>
          {body}
        </ParagraphText>
      </StackLayout>
    </Card.Elevated>
  </>
);
