import { useEffect } from 'react';
import { useLocation, useHistory } from '@leagueplatform/routing';

const useConditionalNavigation = (): void => {
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    let lastRelevantPath = sessionStorage.getItem('lastRelevantPath') || '/';

    const unlisten = history.listen(({ pathname }, action) => {
      const queryParams = new URLSearchParams(window.location.search);
      const linkType = queryParams.get('link');
      const activityId = queryParams.get('activityId');
      const campaignId = queryParams.get('campaignId');

      if (
        action === 'PUSH' &&
        pathname.includes('openLink') &&
        linkType === 'activity' &&
        activityId &&
        campaignId
      ) {
        const targetPath = `/health-journey/campaign/${campaignId}/activity/${activityId}/toolbox`;
        if (lastRelevantPath === '/forms') {
          sessionStorage.setItem('lastRelevantPath', pathname);
          history.replace(targetPath);
        } else {
          sessionStorage.setItem('lastRelevantPath', pathname);
          history.push(targetPath);
        }
      } else if (action === 'POP') {
        if (pathname === '/health-journey' && lastRelevantPath === '/forms') {
          history.replace('/forms');
        }
      } else {
        lastRelevantPath = pathname;
        sessionStorage.setItem('lastRelevantPath', pathname);
      }
    });

    return () => {
      unlisten();
    };
  }, [location, history]);
};

export default useConditionalNavigation;
