import {
  LANG_CHINESE,
  LANG_SPANISH,
  LANG_TAGALOG,
  LANG_VIETNAMESE,
  LOB_DUAL_CONNECT,
  LOB_MEDI_CAL,
} from '@scfhp-web/shared-module';
import { PATHS } from '../commons/constants';
import { Link } from '../commons/types';

// Customization based on LOB (Line of Business)
export const applyLobCustomizations = (item: Link, lob: string): Link => {
  if (lob === LOB_MEDI_CAL && item.text === 'EN_SUBMIT_REQUEST_DUALCONNECT') {
    return { ...item, to: PATHS.EN_SUBMIT_REQUEST_MEDICAL };
  }

  return item;
};

export const applyLanguageCustomizations = (
  item: Link,
  language: string,
  lob: string,
): Link => {
  if (
    lob === LOB_DUAL_CONNECT &&
    item.text === 'STR_NON_DESCRIMINATORY_NOTICE'
  ) {
    if (language === LANG_SPANISH) {
      return { ...item, to: PATHS.ES_NON_DESCRIMINARTION_DUALCONNECT };
    }
    if (language === LANG_CHINESE) {
      return { ...item, to: PATHS.CN_NON_DESCRIMINARTION_DUALCONNECT };
    }
    if (language === LANG_VIETNAMESE) {
      return { ...item, to: PATHS.VT_NON_DESCRIMINARTION_DUALCONNECT };
    }
    if (language === LANG_TAGALOG) {
      return { ...item, to: PATHS.TC_NON_DESCRIMINARTION_DUALCONNECT };
    }

    return { ...item, to: PATHS.EN_NON_DESCRIMINARTION_DUALCONNECT };
  }

  if (lob === LOB_MEDI_CAL && item.text === 'STR_NON_DESCRIMINATORY_NOTICE') {
    if (language === LANG_SPANISH) {
      return { ...item, to: PATHS.ES_NON_DESCRIMINARTION_MEDICAL };
    }
    if (language === LANG_CHINESE) {
      return { ...item, to: PATHS.CN_NON_DESCRIMINARTION_MEDICAL };
    }
    if (language === LANG_VIETNAMESE) {
      return { ...item, to: PATHS.VT_NON_DESCRIMINARTION_MEDICAL };
    }
    if (language === LANG_TAGALOG) {
      return { ...item, to: PATHS.TG_NON_DESCRIMINARTION_MEDICAL };
    }
    return { ...item, to: PATHS.EN_NON_DESCRIMINARTION_MEDICAL };
  }

  return item;
};

export const customizeLinks = (
  baseItems: Link[],
  language: string,
  lob: string,
): Link[] =>
  baseItems.map((item) => {
    let customizedItem = applyLobCustomizations(item, lob);
    customizedItem = applyLanguageCustomizations(customizedItem, language, lob);
    return customizedItem;
  });
