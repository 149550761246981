import { useIntl } from '@leagueplatform/locales';
import {
  LANG_ENGLISH,
  LANG_SPANISH,
  LANG_TAGALOG,
  LANG_VIETNAMESE,
  LANG_CHINESE,
} from '../constants/lob-and-language';

export const useLocale = () => {
  const { locale } = useIntl();

  const getLocaleLanguage = () => {
    if (locale.startsWith('en')) return LANG_ENGLISH;
    if (locale.startsWith('es')) return LANG_SPANISH;
    if (locale.startsWith('tl')) return LANG_TAGALOG;
    if (locale.startsWith('vi')) return LANG_VIETNAMESE;
    if (locale.startsWith('zh')) return LANG_CHINESE;
    return LANG_ENGLISH; // Default to English
  };

  return {
    language: getLocaleLanguage(),
    locale,
  };
};
