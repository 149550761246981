import {
  Card,
  HeadingText,
  TextAction,
  useClickableCard,
} from '@leagueplatform/genesis-core';
import { openLink } from 'utils/open-link';

export interface CarouselCardProps {
  title: string;
  url: string;
  image: string;
  imageAlt: string;
}

export const CarouselCard = ({
  url,
  title,
  image,
  imageAlt,
}: CarouselCardProps) => {
  const { primaryActionRef, clickAreaRef, onCardMouseDown, onCardMouseUp } =
    useClickableCard();
  return (
    <Card.Elevated
      css={{
        maxWidth: '300px',
        flexDirection: 'column',
        backgroundColor: '$surfaceCardSecondary',
        boxShadow: '0px 2px 6px 0px #00000024 !important',
      }}
    >
      <Card.ClickArea
        ref={clickAreaRef}
        onCardMouseUp={onCardMouseUp}
        onCardMouseDown={onCardMouseDown}
        imagePosition={{ '@initial': 'end', '@mobile': 'top' }}
        css={{ flexDirection: 'column', border: 'none !important' }}
      >
        <Card.Image image={image} imageAlt={imageAlt} />
        <Card.Section>
          <TextAction
            as="button"
            onClick={() => openLink(url)}
            ref={primaryActionRef}
          >
            <HeadingText size="md" level="4">
              {title}
            </HeadingText>
          </TextAction>
        </Card.Section>
      </Card.ClickArea>
    </Card.Elevated>
  );
};
