import snakecaseKeys from 'snakecase-keys';
import {
  GET_USER_PROFILE_MESSAGE_TYPE,
  setUserProfile,
  UserProfile,
} from '@leagueplatform/user-profile-api';
import { useQueryClient, useMutation } from 'react-query';

interface Arguments {
  onSuccess?: () => void;
  onError?: () => void;
}

/**
 * @name useUpdateUserProfile
 * @desc React hook to update the user profile with provided payload
 * @arg {object} Arguments
 * @returns {object}
 * @returns {function} object.updateUserProfile
 * @returns {boolean} object.isLoading
 *
 */

export const useUpdateUserProfile = ({
  onSuccess = () => {},
  onError = () => {},
}: Arguments = {}) => {
  const queryClient = useQueryClient();

  const { mutateAsync: updateUserProfileMutation, isLoading } = useMutation(
    (userProfile: Partial<UserProfile>) => setUserProfile(userProfile),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([GET_USER_PROFILE_MESSAGE_TYPE]);
        onSuccess();
      },
      onError: () => {
        onError();
      },
    },
  );

  const updateUserProfile = async (userProfile?: any) => {
    const serializedBody = snakecaseKeys(userProfile);

    await updateUserProfileMutation(serializedBody);
  };

  return { updateUserProfile, isLoading };
};
