import {
  LOB_DEFAULT,
  LOB_DUAL_CONNECT,
  LOB_MEDI_CAL,
} from '../constants/lob-and-language';
import { useGetAppInitDetails } from './use-get-app-init-details.hook';

export const useLOB = () => {
  const { data } = useGetAppInitDetails();

  const lob = data?.segmentation?.lob || LOB_DEFAULT;

  if (lob === LOB_DUAL_CONNECT || lob === LOB_MEDI_CAL) {
    return lob;
  }
  return LOB_DEFAULT;
};
