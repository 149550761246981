import {
  Box,
  StackLayout,
  HeadingText,
  Modal,
} from '@leagueplatform/genesis-core';
import PCPInfoCard from 'components/cards/pcp-info-card.component';
import { FixedFormFooter } from 'components/forms/fixed-form-footer.component';
import { SubmitPCPModal } from 'components/modals/submit-pcp-modal.component';
import { useModal } from 'hooks/use-modal.hook';

export const ChangePCPPage = () => {
  const pcps = [
    {
      title: 'Current Primary Care Doctor (PCP) Information',
      pcpName: 'Raquel Ervin',
      clinicName: 'Optum Health',
      clinicAddress: '701 E El Camino Real, Mountain View, CA 94040',
      phoneNumber: '(408) 241-3801',
    },
    {
      title: 'New Primary Care Doctor (PCP) Information',
      pcpName: 'Raquel Ervin',
      clinicName: 'Optum Health',
      clinicAddress: '701 E El Camino Real, Mountain View, CA 94040',
      phoneNumber: '(408) 241-3801',
    },
  ];

  const { isShowing, toggle } = useModal();

  const handleSubmit = () => {};

  return (
    <StackLayout
      css={{
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '85vh',
        padding: '32px 0 0 0',
        gap: '32px',
      }}
    >
      <Box
        css={{
          maxWidth: '952px',
          width: '100%',
          margin: '0 auto',
          boxShadow: '0px 2px 6px 0px #0000001F',
          overflow: 'hidden',
          padding: '32px',
        }}
      >
        <HeadingText
          level="3"
          size="sm"
          css={{
            fontSize: '24px',
            fontWeight: '700',
            lineHeight: '32px',
            letterSpacing: '0.25px',
            textAlign: 'left',
            marginBottom: '24px',
          }}
        >
          Review Your Information
        </HeadingText>
        <StackLayout
          css={{
            gap: '16px',
            width: '100%',
          }}
        >
          {pcps.map((pcp) => (
            <PCPInfoCard key={pcp.pcpName} {...pcp} />
          ))}
        </StackLayout>
      </Box>
      <StackLayout
        css={{
          position: 'fixed',
          left: '0',
          bottom: '0',
          width: '100%',
          padding: '0 32px',
          boxSizing: 'border-box',
        }}
      >
        <FixedFormFooter
          back={() => {}}
          next={() => toggle()}
          isLoading={false}
          submitText="Submit Request"
          returnText="Return to PCP Selection"
          customStyle={{
            maxWidth: '952px',
          }}
        />
      </StackLayout>
      <Modal.Root
        open={isShowing}
        onOpenChange={() => {
          toggle();
        }}
      >
        <SubmitPCPModal onSubmit={handleSubmit} />
      </Modal.Root>
    </StackLayout>
  );
};
