export const TENANT_ID = 'scfhp';

export const MASONRY = {
  API_VERSION: 2,
  CUSTOM_WIDGET_TYPE_HEALTHLINK_LOGO: 'healthlink_logo',
  HEALTH_JOURNEY_MODULE_KEY: 'health_journey',
  CUSTOM_WIDGET_TYPE_HEALTH_JOURNEY: 'health_journey',
  MESSAGING_MODULE_KEY: 'messaging',
  CUSTOM_WIDGET_TYPE_MESSAGING: 'recent_messages',
  MODULE_KEY_CLAIMS: 'claims',
  CUSTOM_WIDGET_TYPE_CLAIMS: 'recent_claims_cards',
  CUSTOM_WIDGET_TYPE_ACTIVITY: 'activity_stack',
};

export const CAMPAIGN_ID = '';

export const ACTIVITY_ID = '';

export const SENTRY_CONSTANTS = {
  OWNERSHIP_TEAM: 'scfhp-web',
  NO_INFO_AVAILABLE: 'no info available',
};

export const COMMUNICATION_OPTIONS = {
  SMS: 'sms',
  EMAIL: 'email',
};

export const MESSAGE_TYPES = {
  GET_APP_INIT_DETAILS: 'get_app_init_details',
  GET_WIDGET_CONTAINER: 'get_widget_container',
};

export const PATHS = {
  COMMUNICATION_PREFERENCES: '/settings/communication-preferences',
  ABOUT: '/settings/about',
  EN_TERMS_OF_USE_ROUTE: 'https://www.scfhp.com/terms-and-conditions',
  EN_PRIVACY_POLICY: 'https://www.scfhp.com/privacy-policy/',
  CONTACT_US: '/settings/contact-us',
  FORMS: '/forms',
  CHANGE_PCP: '/change-pcp',
  PERSONAL_INFO: '/settings/personal-information',
  EN_SUBMIT_REQUEST_DUALCONNECT:
    'https://www.scfhp.com/forms/grievance-and-appeal-form-dualconnect/',
  EN_SUBMIT_REQUEST_MEDICAL:
    'https://www.scfhp.com/healthcare-plans/medi-cal/frequently-asked-questions/grievance-and-appeal-process/',
  EN_CALENDER_OF_EVENTS: 'https://crc.scfhp.com/crc-calendar/',
  EN_COVERAGE_DETERMINATION_FORM:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/534390fc09f64d48bd15c212df0e31f0/h4045_23106e-redetermination-request-form.pdf',
  EN_NON_DESCRIMINARTION_DUALCONNECT:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/c5796e14c6934a9b9c6f02d02fbe129a/23015e-nondiscrimination-notice.pdf',
  ES_NON_DESCRIMINARTION_DUALCONNECT:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/170f93bc7f7a439ebf2ab5010e932a66/23015s-nondiscrimination-notice.pdf',
  VT_NON_DESCRIMINARTION_DUALCONNECT:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/51b0949186e64b36b3c9bc5ebefc8a16/23015v-nondiscrimination-notice.pdf',
  TC_NON_DESCRIMINARTION_DUALCONNECT:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/839dff94798b498cbab6004a9ee01742/23015c-nondiscrimination-notice.pdf',
  CN_NON_DESCRIMINARTION_DUALCONNECT:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/0936be4793614e61a4da7676dd91cbb2/23015t-nondiscrimination-notice.pdf',
  EN_NON_DESCRIMINARTION_MEDICAL:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/da546163ae774c47a455ca801c3b3a85/50514e-mc-nondiscrimination-notice.pdf',
  ES_NON_DESCRIMINARTION_MEDICAL:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/04baa3b33efa4e6790ac6316ff61b06a/50514s-mc-nondiscrimination-notice.pdf',
  VT_NON_DESCRIMINARTION_MEDICAL:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/1f254d93b5454cdc9f6cc0b6e4f66584/50514v-mc-nondiscrimination-notice.pdf',
  CN_NON_DESCRIMINARTION_MEDICAL:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/0304583918094a2697d39f4ac9e99e1c/50514c-mc-nondiscrimination-notice.pdf',
  TG_NON_DESCRIMINARTION_MEDICAL:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/14426d5b2c4b430e837b9a0c7902fe34/50514t-mc-nondiscrimination-notice.pdf',
  LANGUAGE_ASSISTANCE_MEDICAL:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/3ba0a34895cd4014a6d12bd945da375d/mchk_las.pdf',
  LANGUAGE_ASSISTANCE_DUALCONNECT:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/7ae28ab30228445382356a84f3b5059f/24077-dc-las.pdf',
  FACEBOOK: 'https://www.facebook.com/scfhp/',
  DELEGATE_CONTACT_MEDICAL:
    'https://res.cloudinary.com/dpmykpsih/image/upload/santa-clara-site-299/media/1c338151e73648d9b21268f2c22ebde2/scfhpquickreferenceguide.pdf',
  LINKEDIN: 'www.linkedin.com/company/santa-clara-family-health-plan',
  INSTAGRAM: 'https://www.instagram.com/scfhp/',
  WEBSITE: 'www.scfhp.com',
};

export const REST_ENDPOINTS = {
  USER: '/v1/users',
};
