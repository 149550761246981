import { Suspense } from 'react';

import { ReactQueryDevtools } from 'react-query/devtools';
import { ErrorBoundary } from 'react-error-boundary';
import { BrowserRouter } from '@leagueplatform/routing';
import League from '@leagueplatform/core';
import { useOnlineStatus } from '@leagueplatform/web-common';
import {
  MasonryDriverNodeRenderersRegistry,
  MasonryWSDriverNodeRenderersRegistry,
} from '@leagueplatform/masonry';
import { RecentClaimsCardList } from '@leagueplatform/claims';
import { ActivityStackWidgetV2 } from '@leagueplatform/health-journey';
import {
  FullPageError,
  LoadingIndicator,
  ErrorState,
} from '@leagueplatform/web-common-components';
import { PCPCustomSectionRenderer } from 'components/masonry/pcp-custom-section-renderer.component';
import { CustomerServiceCard } from 'components/cards/customer-service-card.component';
import { SuggestedProgramsCarousel } from 'components/carousels/suggested-programs-carousel.component';
import AppRoutes from './components/AppRoutes';
import { config, routesConfig } from './config';

League.overwriteRoutePaths(routesConfig);

MasonryWSDriverNodeRenderersRegistry.registerRenderer(
  'scfhp',
  'your_care_team_stack',
  PCPCustomSectionRenderer,
);

MasonryDriverNodeRenderersRegistry.registerRenderer(
  'health_journey',
  'activity_stack',
  ActivityStackWidgetV2,
);

MasonryWSDriverNodeRenderersRegistry.registerRenderer(
  'claims',
  'recent_claims_cards',
  RecentClaimsCardList,
);

MasonryWSDriverNodeRenderersRegistry.registerRenderer(
  'scfhp',
  'contact_info_card',
  CustomerServiceCard,
);

MasonryWSDriverNodeRenderersRegistry.registerRenderer(
  'default',
  'carousel',
  SuggestedProgramsCarousel,
);

export function App() {
  return (
    <League.LeagueProvider config={config}>
      <ErrorBoundary fallback={<ErrorState />}>
        <Suspense fallback={<LoadingIndicator />}>
          {useOnlineStatus().isOffline && <FullPageError route="/home" />}
          {useOnlineStatus().isOnline && (
            <BrowserRouter>
              <AppRoutes />
            </BrowserRouter>
          )}
          <ReactQueryDevtools />
        </Suspense>
      </ErrorBoundary>
    </League.LeagueProvider>
  );
}
