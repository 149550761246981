import { useIntl } from '@leagueplatform/locales';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import { useHistory } from '@leagueplatform/routing';
import { MasonryWSDriverConfig } from '@leagueplatform/masonry-api';
import { getMasonryWSDriver } from '@leagueplatform/masonry';
import { MasonryEngine } from '@leagueplatform/masonry-engine';
import { PRODUCT_AREA, usePageViewAnalytics } from '@leagueplatform/analytics';
import { ParagraphText, StackLayout } from '@leagueplatform/genesis-core';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { HeroSection } from 'components/hero-section/hero-section.component';

const config: MasonryWSDriverConfig = {
  appId: 'scfhp_forms_request_homepage',
  apiParams: { stringValue: 'params', integerValue: 20 },
  screenName: 'scfhp-app',
  productArea: PRODUCT_AREA.SETTINGS,
};
const MasonryFormsPageDriver = getMasonryWSDriver(config);

export const FormsPage = () => {
  const history = useHistory();
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'STR_FORMS' }));

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: 'Forms page',
  });

  return (
    <MainPageContainer
      width="100%"
      css={{
        '.GDS-vertical-stack-layout': {
          width: '100%',
        },
      }}
    >
      <HeroSection
        title={formatMessage({ id: 'STR_FORMS_TITLE' })}
        goBack={history.goBack}
      />

      <StackLayout
        orientation="vertical"
        css={{
          paddingTop: '134px',
          maxWidth: '894px',
        }}
      >
        <ParagraphText
          css={{
            paddingY: '$two',
          }}
        >
          {formatMessage({ id: 'STR_FORMS_DESC' })}
        </ParagraphText>

        <MasonryEngine driver={MasonryFormsPageDriver} />
      </StackLayout>
    </MainPageContainer>
  );
};
