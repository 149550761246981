import * as React from 'react';
import { Box, GDSStyleObject } from '@leagueplatform/genesis-core';
import { useLocation } from '@leagueplatform/routing';
import { AppNavBarComponent } from 'components/header-nav/Nav';
import { FooterComponent } from './footer/footer.component';

type BasePageProps = React.PropsWithChildren<{
  css?: GDSStyleObject;
  isPrivatePage?: boolean;
}>;

export const BasePage: React.FC<BasePageProps> = ({
  children,
  isPrivatePage = true,
  css,
}) => {
  const location = useLocation();

  const isGetCarePage = location.pathname === '/get-care';
  const isChangePCPPage = location.pathname === '/change-pcp';

  return (
    <Box
      css={{
        display: 'flex',
        flexDirection: 'column',
        minHeight: '100vh',
        overflowX: 'hidden',
        ...css,
      }}
    >
      {isPrivatePage && (
        <Box css={{ flexShrink: 0 }}>
          <AppNavBarComponent />
        </Box>
      )}
      <Box
        as="main"
        css={{
          flex: '1 0 auto',
          position: 'relative',
          overflowX: 'hidden',
          a: isGetCarePage ? { borderRadius: '99999px' } : undefined,
          ...css,
        }}
      >
        {children}
      </Box>
      {isPrivatePage && !isChangePCPPage && (
        <Box css={{ flexShrink: 0 }}>
          <FooterComponent />
        </Box>
      )}
    </Box>
  );
};
