import { queryHelpers, useMediaQuery } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { usePreferredFirstName } from 'hooks/use-preferred-first-name.hook';
import { useUserDropdownConfig } from 'hooks/use-user-dropdown-config.hook';
import { Options } from 'utils/get-dropdown-links';

function filterUserDropdownConfig(
  config: Options[],
  isMobile: boolean,
): Options[] {
  if (isMobile) return config;

  return config
    .map((section) => ({
      ...section,
      items: section.items.filter((item: any) =>
        [
          'STR_PERSONAL_INFO',
          'STR_MANAGE_ACCOUNT',
          'STR_FORMS',
          'STR_DELETE_YOUR_ACCOUNT',
          'STR_COMMUNICATION',
          'STR_TERMS_AND_CONDITIONS',
          'STR_ABOUT_SANTA_CLARA',
          'STR_CONTACT',
          'STR_PRIVACY_POLICY',
          'STR_LOG_OUT',
        ].includes(item.text),
      ),
    }))
    .filter((section) => section.items.length > 0);
}

function formatFilteredConfig(
  config: Options[],
  formatMessage: (descriptor: any) => string,
): Options[] {
  return config.map((section) => ({
    ...section,
    items: section.items.map((item: any) => ({
      ...item,
      text: formatMessage({ id: item.text }),
    })),
  }));
}

export const useUserDropdownComponent = (handleDeleteAccount: () => void) => {
  const { formatMessage } = useIntl();
  const { data, isLoading, error } = usePreferredFirstName();
  const userDropdownConfig = useUserDropdownConfig(handleDeleteAccount);
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));

  const filteredConfig = filterUserDropdownConfig(userDropdownConfig, isMobile);

  const formattedConfig = formatFilteredConfig(filteredConfig, formatMessage);

  const dropdownMenuItemProps = {
    preferredFirstName: data?.preferredFirstName || '',
    menuItemsConfig: formattedConfig,
  };

  return { data: dropdownMenuItemProps, isLoading, error };
};
