import React from 'react';
import { Box } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { UserInfoDataProps } from '../../common/types';
import { MakeUpdateCard } from './make-update-card.component';

const keyMapping: { [key: string]: string } = {
  firstName: 'STR_FIRST_NAME',
  lastName: 'STR_LAST_NAME',
  email: 'STR_EMAIL_ADDRESS',
  phoneNumber: 'STR_PHONE_NUMBER',
  dateOfBirth: 'STR_DATE_OF_BIRTH',
  homeAddress: 'STR_HOME_ADDRESS',
  mailingAddress: 'STR_MAILING_ADDRESS',
  preferredFirstName: 'STR_DISPLAY_NAME',
};

export const UserProfileDetails = ({ data }: UserInfoDataProps) => {
  const { formatMessage } = useIntl();

  return (
    <>
      <MakeUpdateCard />
      <Box
        css={{
          flex: 1,
        }}
      >
        {Object.entries(data).map(([key, value]) => (
          <Box
            css={{
              flex: 1,
              marginY: '20px',
            }}
            key={key}
          >
            <Box
              css={{
                flex: 1,
                typography: '$subtitleTwo',
                marginTop: '$half',
                paddingRight: '$two',
              }}
            >
              {formatMessage({
                id: (keyMapping[key] as never) || (key as never),
              })}
            </Box>
            <Box
              css={{
                flex: 1,
                typography: '$bodyOne',
                marginTop: '$half',
                paddingRight: '$two',
              }}
            >
              {typeof value === 'string' && value.includes('\n')
                ? value.split('\n').map((line) => (
                    <React.Fragment key={`${line}-${Math.random()}`}>
                      {line}
                      <br />
                    </React.Fragment>
                  ))
                : String(value)}
            </Box>
          </Box>
        ))}
      </Box>
    </>
  );
};
