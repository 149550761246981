export const SENTRY_CONSTANTS = {
  OWNERSHIP_TEAM: 'scfhp-web',
};

export const COMMUNICATION_OPTIONS = {
  EMAIL: 'email',
};

// Will need to get path to terms
export const TERMS_OF_USE_ROUTE = `/terms-of-use`;
