import React from 'react';
import { useIntl } from '@leagueplatform/locales';
import { GDSStyleObject, ParagraphText } from '@leagueplatform/genesis-core';
import { OpensInANewTabMessage } from '@leagueplatform/web-common-components';

interface CaptionWithLinkProps {
  messageId: string;
  linkTextId: string;
  id?: string;
  customStyles?: GDSStyleObject;
  linkHref: string;
  linkFontSize?: string;
  isNewLine?: boolean;
}

export const CaptionWithLink: React.FC<CaptionWithLinkProps> = ({
  messageId,
  linkTextId,
  id,
  customStyles,
  linkHref,
  linkFontSize,
  isNewLine = false,
}) => {
  const { formatMessage } = useIntl();

  const linkStyles: React.CSSProperties = {
    fontSize: linkFontSize ?? 'inherit',
    lineHeight: 'inherit',
    marginLeft: isNewLine ? '0' : '4px',
    display: isNewLine ? 'block' : 'inline',
  };

  return (
    <ParagraphText size="xs" id={id}>
      {formatMessage({ id: messageId as never })}
      <span style={linkStyles}>
        <a
          href={linkHref}
          target="_blank"
          rel="noreferrer"
          style={
            {
              fontWeight: 400,
              fontSize: '12px',
              textDecoration: 'underline',
              ...customStyles,
            } as never
          }
        >
          {formatMessage({ id: linkTextId as never })}
          <OpensInANewTabMessage />
        </a>
      </span>
    </ParagraphText>
  );
};
