import {
  StackLayout,
  HeadingText,
  ParagraphText,
  useMediaQuery,
  queryHelpers,
  ImageMessage,
} from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';
import { BaseModalContainer } from 'components/modals/common/base-modal.component';
import exitWarning from 'assets/pcp-modal.svg';
import { ModalActionButton } from './common/modal-action-button.component';

export interface Props {
  onSubmit: () => void;
}
export const SubmitPCPModal = ({ onSubmit }: Props) => {
  const { formatMessage } = useIntl();
  const isMobile = useMediaQuery(queryHelpers.only('mobile'));

  return (
    <BaseModalContainer
      footer={
        <StackLayout
          horizontalAlignment="end"
          orientation={{
            '@initial': 'horizontal',
            '@mobile': 'vertical',
          }}
          spacing="$one"
          css={{ flexGrow: 0 }}
        >
          <ModalActionButton
            actionType="cancel"
            labelId="STR_GO_HOME"
            isMobile={isMobile}
            cssStyle={{
              color: '$secondaryTextDefault',
              borderRadius: '62px',
              borderColor: '$primaryBackground',
            }}
          />
          <ModalActionButton
            actionType="submit"
            onClick={onSubmit}
            labelId="STR_GO_TO_CARE_PAGE"
            isMobile={isMobile}
            isDestructive
            cssStyle={{
              borderRadius: '62px',
              background: '$primaryBackgroundDefault',
            }}
          />
        </StackLayout>
      }
    >
      <ImageMessage
        image={exitWarning}
        css={{
          flexGrow: 1,
          textAlign: 'left',
          '@mobile': { textAlign: 'center' },
        }}
      >
        <HeadingText
          level="2"
          size={{
            '@initial': 'lg',
            '@mobile': 'xl',
          }}
          css={{
            paddingBlock: '$one',
            fontWeight: 'bold',
            textAlign: 'center',
          }}
        >
          {formatMessage({ id: 'STR_PCP_MODAL_TITLE' })}
        </HeadingText>
        <ParagraphText css={{ textAlign: 'center' }}>
          {formatMessage({ id: 'STR_PCP_MODAL_BODY' })}
        </ParagraphText>
      </ImageMessage>
    </BaseModalContainer>
  );
};
