import { GDSIconProps, GDSStyleObject } from '@leagueplatform/genesis-core';
import {
  LANG_CHINESE,
  LANG_SPANISH,
  LANG_TAGALOG,
  LANG_VIETNAMESE,
  LOB_DUAL_CONNECT,
  LOB_MEDI_CAL,
} from '@scfhp-web/shared-module';
import { PATHS } from 'commons/constants';

export interface FooterLinkProps {
  text: string;
  url?: string;
  linkProps?: GDSStyleObject;
  icon?: GDSIconProps['icon'];
  target?: string;
}

const boldTextLink: GDSStyleObject = {
  fontWeight: 'bold',
};

// Customization based on LOB and language
const customizeFooterLinks = (
  baseLinks: FooterLinkProps[],
  lob: string,
  language: string,
): FooterLinkProps[] =>
  baseLinks
    .filter(
      (link) =>
        !(link.text === 'STR_DELEGATION_CONTACT_INFO' && lob !== LOB_MEDI_CAL),
    )
    .map((link) => {
      // Customize URLs based on LOB and language
      if (
        lob === LOB_DUAL_CONNECT &&
        link.text === 'STR_NON_DESCRIMINATORY_NOTICE'
      ) {
        switch (language) {
          case LANG_SPANISH:
            return { ...link, url: PATHS.ES_NON_DESCRIMINARTION_DUALCONNECT };
          case LANG_CHINESE:
            return { ...link, url: PATHS.CN_NON_DESCRIMINARTION_DUALCONNECT };
          case LANG_VIETNAMESE:
            return { ...link, url: PATHS.VT_NON_DESCRIMINARTION_DUALCONNECT };
          case LANG_TAGALOG:
            return { ...link, url: PATHS.TC_NON_DESCRIMINARTION_DUALCONNECT };
          default:
            return { ...link, url: PATHS.EN_NON_DESCRIMINARTION_DUALCONNECT };
        }
      }

      if (
        lob === LOB_MEDI_CAL &&
        link.text === 'STR_NON_DESCRIMINATORY_NOTICE'
      ) {
        switch (language) {
          case LANG_SPANISH:
            return { ...link, url: PATHS.ES_NON_DESCRIMINARTION_MEDICAL };
          case LANG_CHINESE:
            return { ...link, url: PATHS.CN_NON_DESCRIMINARTION_MEDICAL };
          case LANG_VIETNAMESE:
            return { ...link, url: PATHS.VT_NON_DESCRIMINARTION_MEDICAL };
          case LANG_TAGALOG:
            return { ...link, url: PATHS.TG_NON_DESCRIMINARTION_MEDICAL };
          default:
            return { ...link, url: PATHS.EN_NON_DESCRIMINARTION_MEDICAL };
        }
      }

      return link;
    });

export const footerLinksConfig = (
  lob: string,
  language: string,
): FooterLinkProps[] => {
  const baseLinks: FooterLinkProps[] = [
    {
      text: 'STR_TERMS_AND_CONDITIONS',
      url: PATHS.EN_TERMS_OF_USE_ROUTE,
      target: '_blank',
      linkProps: boldTextLink,
    },
    {
      text: 'STR_PRIVACY_PRACTICES',
      url: PATHS.EN_PRIVACY_POLICY,
      target: '_blank',
      linkProps: boldTextLink,
    },
    {
      text: 'STR_NON_DESCRIMINATORY_NOTICE',
      url: PATHS.EN_NON_DESCRIMINARTION_DUALCONNECT,
      target: '_blank',
      linkProps: boldTextLink,
    },
    {
      text: 'STR_LANGUAGE_ASSISTANCE',
      url: PATHS.LANGUAGE_ASSISTANCE_DUALCONNECT,
      target: '_blank',
      linkProps: boldTextLink,
    },
    {
      text: 'STR_DELEGATION_CONTACT_INFO',
      url: PATHS.DELEGATE_CONTACT_MEDICAL,
      target: '_blank',
      linkProps: boldTextLink,
    },
  ];

  return customizeFooterLinks(baseLinks, lob, language);
};

export const footerSubLinksConfig = (): FooterLinkProps[] => [
  {
    text: 'STR_SCFHP_WEBSITE',
    url: PATHS.WEBSITE,
    target: '_blank',
    icon: '',
  },
  {
    text: 'STR_FACEBOOK',
    url: PATHS.FACEBOOK,
    target: '_blank',
    icon: '',
  },
  {
    text: 'STR_INSTAGRAM',
    url: PATHS.INSTAGRAM,
    target: '_blank',
    icon: '',
  },
  {
    text: 'STR_CALENDER_OF_EVENTS',
    url: PATHS.EN_CALENDER_OF_EVENTS,
    target: '_blank',
    icon: '',
  },
];
