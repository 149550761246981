import { ChangeEvent, MouseEvent, useRef } from 'react';
import { useIntl } from '@leagueplatform/locales';
import { toastActions, TOAST_STATUS } from '@leagueplatform/toast-messages';
import {
  Modal,
  StackLayout,
  Box,
  Button,
  styled,
} from '@leagueplatform/genesis-core';
import { getContentUrl } from '@leagueplatform/league-content-api';
import {
  usePersonalInformation,
  useUpdateUserProfile,
} from '@scfhp-web/shared-module';
import { useUploadProfilePhoto } from 'hooks/use-upload-profile-photo.hook';
import { ProfileImageErrorModal } from 'components/modals/profile-image-error-modal.component';

const InvisibleInput = styled('input', {
  display: 'none',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
});

type EditProfilePhotoProps = {
  fileInputOnClickCallback?: (hasProfilePhoto?: boolean) => void;
};

export const EditProfilePhoto = ({
  fileInputOnClickCallback = () => {},
}: EditProfilePhotoProps) => {
  const { formatMessage } = useIntl();

  const { data } = usePersonalInformation();

  const [
    { profilePhotoId, uploadError },
    { setUploadError, uploadProfilePhoto, resetProfilePhotoId },
  ] = useUploadProfilePhoto(data);

  const { updateUserProfile } = useUpdateUserProfile({
    onSuccess: () => {
      toastActions.add({
        type: TOAST_STATUS.SUCCESS,
        textId: 'STR_PROFILE_PHOTO_UPDATED',
      });
    },
    onError: () => {
      resetProfilePhotoId();
      toastActions.add({
        type: TOAST_STATUS.ERROR,
        textId: 'STR_PROFILE_PHOTO_UPDATE_FAILED',
      });
    },
  });

  const initials = data?.userInitials;
  const inputRef = useRef<HTMLInputElement>(null);

  return (
    <>
      <Modal.Root open={uploadError} onOpenChange={() => setUploadError(false)}>
        <ProfileImageErrorModal />
      </Modal.Root>
      <StackLayout horizontalAlignment="center">
        <Box
          css={{
            position: 'relative',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            data-testid="profile-image"
            className={profilePhotoId ? 'background-image' : ''}
            css={{
              width: 128,
              height: 128,
              borderRadius: '$circle',
              backgroundColor: '$surfaceCardPrimary',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              typography: '$headingOne',
              ...(profilePhotoId && {
                backgroundImage: `url(${getContentUrl(profilePhotoId)})`,
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
                backgroundPosition: '50%',
              }),
            }}
          >
            {!profilePhotoId && initials}
          </Box>
          <InvisibleInput
            id="photo-file-input"
            ref={inputRef}
            type="file"
            accept="image/*"
            data-testid="file-input"
            onChange={async (e: ChangeEvent<HTMLInputElement>) => {
              if (!e.currentTarget.files) return;
              const file = e?.currentTarget?.files[0];
              if (file) {
                const newID = await uploadProfilePhoto(file);
                if (newID)
                  updateUserProfile({
                    avatar: { imageId: newID },
                  });
              }
            }}
            onClick={(e: MouseEvent<HTMLInputElement>) => {
              (e.target as HTMLInputElement).value = '';
              fileInputOnClickCallback(!!profilePhotoId);
            }}
          />
          <Button
            onClick={() => inputRef?.current?.click()}
            quiet
            css={{
              marginTop: '$half',
              backgroundColor: 'transparent',
              color: '$interactiveActionPrimary',
              outline: 'none',
              '&:hover': {
                background: 'transparent',
              },
            }}
          >
            {formatMessage({
              id: profilePhotoId
                ? 'STR_UPDATE_PROFILE_PHOTO'
                : 'STR_ADD_PROFILE_PHOTO',
            })}
          </Button>
        </Box>
      </StackLayout>
    </>
  );
};
