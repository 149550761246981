import React from 'react';
import { Carousel } from '@leagueplatform/web-common-components';
import { CarouselCard } from 'components/cards/carousel-card.component';
import { HeadingText, StackLayout } from '@leagueplatform/genesis-core';
import { useIntl } from '@leagueplatform/locales';

type CarouselItem = {
  image: string;
  image_alt_text: string;
  link_url: string;
  title: string;
};

type SuggestedProgramsCarouselProps = {
  items: CarouselItem[];
  title: string;
};

export const SuggestedProgramsCarousel: React.FC<
  SuggestedProgramsCarouselProps
> = ({ items, title }) => {
  const { formatMessage } = useIntl();

  return (
    <Carousel
      name={title}
      heading={
        <StackLayout
          orientation="horizontal"
          horizontalAlignment="spaceBetween"
          verticalAlignment="center"
          css={{
            width: '100%',
            marginTop: '$two',
          }}
        >
          <HeadingText level="3" size="xl">
            {title}
          </HeadingText>
        </StackLayout>
      }
      linkCta={formatMessage({ id: 'STR_VIEW_ALL' })}
      linkUrl="/"
      showProgressDots
      sideArrows
    >
      {items.map((item) => (
        <CarouselCard
          key={item.title}
          url={item.link_url}
          title={item.title}
          image={item.image}
          imageAlt={item.image_alt_text}
        />
      ))}
    </Carousel>
  );
};
