import { useHistory } from '@leagueplatform/routing';
import { useIntl } from '@leagueplatform/locales';
import { MainPageContainer } from '@leagueplatform/web-common-components';
import {
  PRODUCT_AREA,
  SCREEN_NAMES,
  usePageViewAnalytics,
} from '@leagueplatform/analytics';
import {
  HeadingText,
  Box,
  StackLayout,
  ParagraphText,
} from '@leagueplatform/genesis-core';
import { useDocumentTitle } from '@leagueplatform/web-common';
import { HeroSection } from 'components/hero-section/hero-section.component';
import { CheckConfirmation } from 'components/check-confirmation/check-confirmation.component';
import FlexibleCardLayout from 'components/cards/flexible-card-layout.component';
import { useCommunicationPreferences } from 'hooks/use-communication-preferences.hook';
import { CaptionWithLink } from 'components/caption-with-link.component/caption-with-link.component';
import { PATHS } from 'commons/constants';

export const CommunicationPreferencesPage = () => {
  const { formatMessage } = useIntl();
  useDocumentTitle(formatMessage({ id: 'STR_COMMUNICATION_PREFERENCES' }));

  const history = useHistory();
  const [
    { allowEmailNotification, isLoading, isWaiting, email },
    { setEmailNotification },
  ] = useCommunicationPreferences();

  usePageViewAnalytics({
    product_area: PRODUCT_AREA.SETTINGS,
    screen_name: SCREEN_NAMES.COMMUNICATION_PREFERENCES,
  });

  return (
    <MainPageContainer width="100%">
      <HeroSection
        title={formatMessage({ id: 'STR_COMMUNICATION_PREFERENCES' })}
        goBack={history.goBack}
      />
      <FlexibleCardLayout>
        <CheckConfirmation
          label={formatMessage({
            id: 'STR_COMMUNICATION_PREFERENCES_ALLOW_EMAIL',
          })}
          name="allowEmailNotification"
          checked={allowEmailNotification}
          handleCheck={setEmailNotification}
          loading={isLoading || isWaiting === 'email'}
          ariaDisabled={!!isWaiting}
        />
        <StackLayout orientation="vertical" css={{ paddingX: '$twoAndHalf' }}>
          {allowEmailNotification && (
            <StackLayout
              orientation="vertical"
              spacing="$half"
              horizontalAlignment="stretch"
              css={{ marginY: '$one' }}
            >
              <HeadingText level="2" size="xs" css={{ lineHeight: '20px' }}>
                {formatMessage({ id: 'STR_APP_NOTIFICATIONS' })}
              </HeadingText>
              <ParagraphText css={{ fontSize: '14px', lineHeight: '20px' }}>
                {email}
              </ParagraphText>
              <ParagraphText css={{ fontSize: '12px', lineHeight: '16px' }}>
                {formatMessage({
                  id: 'STR_USER_NOTIFIED_EMAIL_SUBTITLE',
                })}
              </ParagraphText>
            </StackLayout>
          )}

          <Box css={{ marginY: '$half' }}>
            <CaptionWithLink
              messageId="STR_EMAIL_NOTIFICATION_DISCLAIMER"
              linkHref={PATHS.EN_TERMS_OF_USE_ROUTE}
              linkTextId="STR_VISIT_OUR_TERMS"
              customStyles={{ color: '$onSurfaceTextSubdued' }}
            />
          </Box>
        </StackLayout>
      </FlexibleCardLayout>
    </MainPageContainer>
  );
};
